import Link from 'next/link';
import { Badge } from 'react-bootstrap';
import React from 'react';
import { Icon as FeatherIcon } from 'react-feather';

type SidebarItemProps = {
  name: string;
  badgeColor?: string;
  badgeText?: string;
  icon?: FeatherIcon;
  pathName?: string;
  to: string;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  name,
  badgeColor,
  badgeText,
  icon: Icon,
  pathName,
  to,
}) => {
  const getSidebarItemClass = (path: string) => {
    return pathName === path ? 'active' : '';
  };

  return (
    <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
      <Link href={to}>
        <a className="sidebar-link">
          {Icon ? <Icon size={18} className="align-middle me-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge bg={badgeColor} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </a>
      </Link>
    </li>
  );
};

export default SidebarItem;
